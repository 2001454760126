<template>
  <v-dialog :value="dialogOrder" :persistent="true" fullscreen>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeOrderDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Orden #{{ selectedOrder.order_number }}</v-toolbar-title
        >
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 align="center">Datos de la orden</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <h2>Contacto</h2>
            <h4>Nombre</h4>
            <p>{{ selectedOrder.shipping_address.name }}</p>
            <h4>Correo Electronico</h4>
            <a :href="'mailto:' + selectedOrder.contact_email">{{
              selectedOrder.email
            }}</a>
            <h4>Telefono</h4>
            <a :href="'tel:' + selectedOrder.phone">{{
              selectedOrder.phone
            }}</a>
            <h4>Compañia</h4>
            <p>{{ selectedOrder.shipping_address.company }}</p>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <h2>Datos de envio</h2>
            <h4>Direccion</h4>
            <p>
              {{
                selectedOrder.shipping_address.address1 +
                " " +
                selectedOrder.shipping_address.address2
              }}
            </p>
            <h4>Ciudad</h4>
            <p>{{ selectedOrder.shipping_address.city }}</p>
            <h4>Estado</h4>
            <p>{{ selectedOrder.shipping_address.province }}</p>
            <h4>Codigo Postal</h4>
            <p>{{ selectedOrder.shipping_address.zip }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 align="center">Productos de la orden</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h3>Contenido del paquete</h3>
            <v-list-item v-for="(item, i) in selectedOrder.line_items" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-spacer></v-spacer>
                <v-list-item-subtitle
                  >{{ item.quantity }} Pzas</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6" v-if="selectedOrder.orderInformation.groups">
            <h3>Grupos del pedido</h3>
            <v-list-item
              v-for="(item, i) in selectedOrder.orderInformation.groups"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" v-if="selectedOrder.orderInformation">
            <h3>Nombres y configuraciones de las cuentas</h3>
            <v-expansion-panels>
            <v-expansion-panel
              v-for="(model, index) in selectedOrder.orderInformation.products"
              :key="index"
            >
              <v-expansion-panel-header class="white--text" color="primary">{{ model.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    v-for="(item, i) in model.accounts"
                    :key="i"
                  >
                    <v-sheet elevation="10" rounded="xl">
                      <v-sheet
                        class="pl-5 primary"
                        dark
                        rounded="t-xl"
                      >
                        Nombre de la cuenta: <p>{{ item.name }}</p>
                      </v-sheet>
                      <p>Contraseña: {{ item.password }}</p>
                      <div class="pa-4">
                        <v-chip-group active-class="primary--text" column>
                          <v-chip v-for="tag in item.conf" :key="tag">
                            {{ namePrivileges(tag).text }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              </v-expansion-panel-content>
              <!-- <v-list-item v-for="(item, i) in model.accounts" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip >
                      Hola
                    </v-chip>
                  </v-chip-group>
                </v-list-item-content>
              </v-list-item> -->
            </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
      <!-- <FAB v-if="!canRead()"
        :buttons="[{
          color: 'primary',
          icon: 'mdi-content-save-settings',
          fn: saveChangesService,
          name: 'Guardar'
        },{
          color: 'red',
          icon: 'mdi-cancel',
          fn: cancelTicket,
          name: 'Cancelar'
        },{
          color: 'green',
          icon: 'mdi-send',
          fn: endTicket,
          name: 'Finalizar'
        }]"/> -->
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations, mapState } from "vuex";
// import FAB from '../../Complements&Buttons/fab'

export default {
  components: {},
  computed: {
    ...mapState("Orders", ["dialogOrder", "selectedOrder","privilegeList"]),
  },
  methods: {
    namePrivileges(val){
      const x = this.privilegeList.find(el=>el.value==val)
      return x

    },
    ...mapMutations("Orders", ["closeOrderDialog"]),
  },
};
</script>