<template>
  <div id="app">
    <v-app id="create">
      <v-container >
        <v-row>
          <v-col cols="12" >
            <v-data-table
              :headers="headers"
              :items="orders"
              item-key="order_number"
              no-data-text="No data found"
              loading-text="Cargando..."
              @click:row="openOrderDialog($event)"
            >
             <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                      <v-spacer></v-spacer>
                      <!-- <SearchBar :setSearchTerm="setSearchterm"/>               -->
                  </v-row>
                </v-toolbar>
              </template>
             
              <template  v-slot:item.financial_status="{ item }">´
                <v-chip :color="statusOrder(item.financial_status).color" :text-color="statusOrder(item.financial_status).textColor">
                  {{statusOrder(item.financial_status).text}}
                </v-chip>
              </template>
              <template  v-slot:item.total_line_items_price="{ item }">´
                ${{item.total_line_items_price}}
              </template>
              <template v-slot:no-data>
                No registers detected
              </template>
            </v-data-table>
            <OrderDialog/>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
// import SearchBar from '../Complements&Buttons/searchBar'
import OrderDialog from '../Dialogs/OrdersDialogs/orderInformation.vue'
import { mapActions, mapState } from 'vuex';

export default {
   created() {
    this.getOrdersFromShopify();
  },
  components:{
    // SearchBar,
    OrderDialog
  },
  computed: {
    ...mapState("Orders",["headers","orders","loading","search","Info"])
    
  },
  methods: {

    ...mapActions('Orders',['getOrdersFromShopify','openOrderDialog']),
    statusOrder(status){
        switch(status){
          case 'pending':{
            return {text: 'Pendiente',color:'yellow',textColor:'black'}
          }
          case 'paid':{
            return {text: 'Pagado',color:'green',textColor:'white'}
          }
          case 'authorized':{
            return {text: 'Authorizado',color:'green',textColor:'white'}
          }
          case 'partially_paid':{
            return {text: 'Pagado parcialmente',color:'yellow',textColor:'black'}
          }
          case 'partially_refunded':{
            return {text: 'Regresado parcialmente',color:'red',textColor:'white'}
          }
          case 'refunded':{
            return {text: 'Regresado',color:'red',textColor:'white'}
          }
          case 'voided':{
            return {text: 'Anulado',color:'red',textColor:'white'}
          }
        }
    }
  },
 
  data() {
    return {
      expanded: [],
      textAreaValue: "",
      fab: false,
    };
  },
};
</script>