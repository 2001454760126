<template>
  <div id="app">
      <v-app id="inspire">
        <Navbar/>
        <TableOrders/>
      </v-app>
  </div>
</template>

<script>
import TableOrders from '../components/Tables/TableOrders.vue'
import Navbar from '../components/Navbar';

export default {
    components:{
        Navbar,
        TableOrders
    },
}
</script>

<style scoped>

</style>